import type { Option } from "@kippie/ui/src/runtime/components";
import { isBefore, isAfter } from "date-fns";

export function storeOpen(days: Option[]): boolean {
	if (!days.length) return false;

	const now: Date = new Date();
	const hours: string[] = days.slice(now.getDay() - 1)[0].value.split(" - ") || [];

	if (hours.length < 2) return false;

	const start: Date = new Date();
	const end: Date = new Date();

	start.setHours(+hours[0].split(":")[0]);
	start.setMinutes(+hours[0].split(":")[1]);
	end.setHours(+hours[1].split(":")[0]);
	end.setMinutes(+hours[1].split(":")[1]);

	return isBefore(now, end) && isAfter(now, start);
}
